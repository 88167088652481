export const FILTER_KEY_SEARCH = 'Search';
export const FILTER_KEY_FILTER = 'filter';
export const FILTER_KEY_SORT = 'Sort';
export const FILTER_KEY_MODE = 'filter';
export const FILTER_KEY_PAGE = 'PageIndex';
export const FILTER_KEY_SIZE = 'PageSize';
export const FILTER_KEY_CONTENT_TYPES = 'contentTypes';
export const FILTER_KEY_PRICE = 'Price';
export const FILTER_KEY_TYPE = 'type';

export enum FilterControlTypes {
    Dropdown = 'Dropdown',
    Slider = 'Slider',
}

export const PAGE_SIZE = 25;
export const DEFAULT_SORTING = 'recommended';
export const SORTING_RELEVANCE = 'relevance';

export const SORTING_FACET_KEY = 'sorting';

export const DEFAULT_SORTING_HELLO_RETAIL = 'relevance';
export const FILTER_KEY_PRICE_HELLO_RETAIL = 'price';
