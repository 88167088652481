import { ParsedUrlQuery } from 'querystring';
import { NextRouter } from 'next/router';
import { FacetList } from './filter.types';
import { IFullSearchResult, IIndeksRetailFacetOutput, ISanitizedFilterOutput } from '$models';
import {
    FILTER_KEY_PAGE,
    FILTER_KEY_PRICE,
    FILTER_KEY_SEARCH,
    FILTER_KEY_MODE,
    FILTER_KEY_SORT,
    DEFAULT_SORTING,
    FILTER_KEY_PRICE_HELLO_RETAIL,
    DEFAULT_SORTING_HELLO_RETAIL,
} from './filter';
import { isDefined, canUseDOM } from '$lib/helpers';
import { FILTER_KEY_CONTENT_TYPES, FILTER_KEY_FILTER } from '.';
import { SearchContentTypes } from '~/modules/search';
import { SearchContentTypesOldSearch } from '~/modules/search/interfaces/search';

export const getFilterParamsFromResult = (result: ISanitizedFilterOutput | undefined): FacetList => {
    const facetList: FacetList = {};

    if (!result) {
        return facetList;
    }
    const { facets } = result;

    facets
        ?.filter((f) => f.key !== FILTER_KEY_PRICE)
        .forEach((f) => {
            const selectedFacets =
                f.facetResults
                    ?.filter((fr) => fr.isSelected)
                    .map((fr) => fr.query?.value)
                    .filter(isDefined) || [];
            if (selectedFacets.length) facetList[`${f.key}`] = selectedFacets;
        });

    const priceFacet = result?.facets?.find((f) => f.key === FILTER_KEY_PRICE);
    if (priceFacet?.isSelected) {
        facetList[FILTER_KEY_PRICE] = [`${priceFacet.currentMin}|${priceFacet.currentMax}`];
    }

    if (result?.selectedSortOrder) {
        facetList[FILTER_KEY_SORT] = [result.selectedSortOrder];
    }
    return facetList;
};

export const getFilterParamsFromHelloRetailResult = (result?: IIndeksRetailFacetOutput[]): FacetList => {
    const facetList: FacetList = {};

    if (!result) {
        return facetList;
    }
    result
        ?.filter((f) => f.key !== FILTER_KEY_PRICE_HELLO_RETAIL)
        .forEach((f) => {
            const selectedFacets =
                f.facetResults
                    ?.filter((fr) => fr.isSelected)
                    .map((fr) => fr.query?.value)
                    .filter(isDefined) || [];
            if (selectedFacets.length) {
                const key = selectedFacets[0].split(':')[0];
                if (key) {
                    facetList[`${key}`] = selectedFacets.map((sf) => sf.split(':')[1]);
                }
            }
        });
    const priceFacet = result?.find((f) => f.key === FILTER_KEY_PRICE_HELLO_RETAIL);
    if (priceFacet?.isSelected) {
        facetList[FILTER_KEY_PRICE_HELLO_RETAIL] = [`${priceFacet?.currentMin},${priceFacet?.currentMax}`];
    }
    return facetList;
};

export const mapFacetListToQuery = (facetlist: FacetList) => {
    const queries: string[] = [];

    // facets
    const facets =
        Object.entries(facetlist)
            .filter(([key]) => key !== FILTER_KEY_PAGE)
            .map(([key, value]) => {
                return Array.isArray(value)
                    ? value?.map((valueEntry) => `${key}=${encodeURIComponent(valueEntry)}`)
                    : [`${key}=${encodeURIComponent(value)}`];
            })
            .reduce((a, c) => [...c, ...a], [])
            .join('&')
            .replace(FILTER_KEY_FILTER, FILTER_KEY_CONTENT_TYPES) || '';

    if (facets) {
        queries.push(facets);
    }

    return queries.length ? `${queries.join('&')}` : '';
};

export const getFacetParamsFromUrl = (query: ParsedUrlQuery): FacetList => {
    if (!query || !Object.entries(query).length) return {};

    const facetList = { ...query };
    delete facetList['slug'];

    Object.entries(query).forEach(([key, values]) => {
        if (!Array.isArray(values)) query[key] = [values];
    });

    return facetList as FacetList;
};

export const updateQueryParams = (
    router: NextRouter,
    mode: SearchContentTypes,
    searchResult?: IFullSearchResult,
    forcedMode?: boolean,
    userInteracted?: boolean
) => {
    if (!canUseDOM()) {
        return;
    }

    if (!userInteracted) {
        return;
    }

    if (!searchResult) {
        const newUrl = `${router.asPath.split('?')?.[0]}`;
        window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
        return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let query: { [key: string]: any } = {};

    if (searchResult.searchTerm) {
        query[FILTER_KEY_SEARCH] = searchResult.searchTerm;
    }

    if (mode !== 'all') {
        query[FILTER_KEY_MODE] = mode;
    }

    if (mode === 'all' || mode === 'products') {
        const productFilterParams = getFilterParamsFromResult(searchResult.products?.filterResult);
        query = { ...query, ...productFilterParams };
        query[FILTER_KEY_PAGE] = searchResult.products?.filterResult?.pageIndex;
    } else {
        const pagingData = searchResult[mode as unknown as SearchContentTypesOldSearch];

        if (pagingData) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { pageIndex } = pagingData as any;

            query[FILTER_KEY_PAGE] = pageIndex;
        }
    }

    Object.entries(query).forEach(([key, values]) => {
        if (!Array.isArray(values)) query[key] = [values];
    });

    if (forcedMode) {
        delete query[FILTER_KEY_MODE];
    }

    if (query[FILTER_KEY_SEARCH]?.[0] === '*') {
        delete query[FILTER_KEY_SEARCH];
    }

    if (query[FILTER_KEY_SORT]?.[0] === DEFAULT_SORTING) {
        delete query[FILTER_KEY_SORT];
    }

    if (!isDefined(query[FILTER_KEY_PAGE]) || query[FILTER_KEY_PAGE] < 2) {
        delete query[FILTER_KEY_PAGE];
    }

    const queryString =
        Object.entries(query)
            .map(([key, value]) =>
                Array.isArray(value)
                    ? value?.map((valueEntry) => `${key}=${encodeURIComponent(valueEntry)}`)
                    : [`${key}=${encodeURIComponent(value)}`]
            )
            .reduce((a, c) => [...c, ...a], [])
            .sort()
            .join('&') || '';

    const newUrl = `${router.asPath.split('?')?.[0]}${queryString ? `?${queryString}` : ''}`;

    window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
};

export const updateLocalQueryParams = (
    router: NextRouter,
    mode: SearchContentTypes,
    searchResult?: IFullSearchResult,
    forcedMode?: boolean,
    userInteracted?: boolean
) => {
    if (!canUseDOM()) {
        return;
    }

    if (!userInteracted) {
        return;
    }

    if (!searchResult) {
        const newUrl = `${router.asPath.split('?')?.[0]}`;
        window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
        return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: { [key: string]: any } = {};

    if (searchResult.searchTerm) {
        query[FILTER_KEY_SEARCH] = searchResult.searchTerm;
    }

    if (mode !== 'all') {
        query[FILTER_KEY_MODE] = mode;
    }

    if (mode === SearchContentTypes.Series) {
        query[FILTER_KEY_PAGE] = searchResult.series?.pageIndex;
    }
    if (mode === SearchContentTypes.Authors) {
        query[FILTER_KEY_PAGE] = searchResult.authors?.pageIndex;
    }

    Object.entries(query).forEach(([key, values]) => {
        if (!Array.isArray(values)) query[key] = [values];
    });

    if (forcedMode) {
        // delete query[FILTER_KEY_MODE];
    }

    if (query[FILTER_KEY_SEARCH]?.[0] === '*') {
        delete query[FILTER_KEY_SEARCH];
    }

    if (query[FILTER_KEY_SORT]?.[0] === DEFAULT_SORTING) {
        delete query[FILTER_KEY_SORT];
    }

    if (!isDefined(query[FILTER_KEY_PAGE]) || query[FILTER_KEY_PAGE] < 1) {
        delete query[FILTER_KEY_PAGE];
    }

    const queryString =
        Object.entries(query)
            .map(([key, value]) =>
                Array.isArray(value)
                    ? value?.map((valueEntry) => `${key}=${encodeURIComponent(valueEntry)}`)
                    : [`${key}=${encodeURIComponent(value)}`]
            )
            .reduce((a, c) => [...c, ...a], [])
            .sort()
            .join('&') || '';

    const newUrl = `${router.asPath.split('?')?.[0]}${queryString ? `?${queryString}` : ''}`;

    window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
};

export const updateHelloRetailQueryParams = (
    router: NextRouter,
    mode: SearchContentTypes,
    searchTerm: string,
    hasSearchResult?: boolean,
    filters?: IIndeksRetailFacetOutput[],
    currentIndex?: number,
    selectedSortOrderKey?: string,
    userInteracted?: boolean
) => {
    if (!canUseDOM()) {
        return;
    }

    if (!userInteracted) {
        return;
    }

    if (!hasSearchResult) {
        let newUrl = `${router.asPath.split('?')?.[0]}`;

        if (searchTerm) {
            newUrl += `?${FILTER_KEY_SEARCH}=${searchTerm}`;
        }
        if (mode !== 'all') {
            newUrl += `${newUrl.includes('?') ? '&' : '?'}${FILTER_KEY_MODE}=${mode}`;
        }
        window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
        return;
    }

    let query: { [key: string]: any } = {};

    if (searchTerm) {
        query[FILTER_KEY_SEARCH] = searchTerm;
    }

    if (mode !== 'all') {
        query[FILTER_KEY_MODE] = mode;
    }

    if (mode === 'all' || mode === 'products') {
        const productFilterParams = getFilterParamsFromHelloRetailResult(filters);
        query = { ...query, ...productFilterParams };
        query[FILTER_KEY_PAGE] = currentIndex;
    } else {
        query[FILTER_KEY_PAGE] = currentIndex;
    }

    Object.entries(query).forEach(([key, values]) => {
        if (!Array.isArray(values)) query[key] = [values];
    });

    if (query[FILTER_KEY_SEARCH]?.[0] === '*') {
        delete query[FILTER_KEY_SEARCH];
    }

    if (selectedSortOrderKey && selectedSortOrderKey !== DEFAULT_SORTING_HELLO_RETAIL) {
        query[FILTER_KEY_SORT] = selectedSortOrderKey;
    }

    if (!isDefined(query[FILTER_KEY_PAGE]) || query[FILTER_KEY_PAGE] < 2) {
        delete query[FILTER_KEY_PAGE];
    }

    const queryString =
        Object.entries(query)
            .map(([key, value]) =>
                Array.isArray(value)
                    ? value?.map((valueEntry) => `${key}=${encodeURIComponent(valueEntry)}`)
                    : [`${key}=${encodeURIComponent(value)}`]
            )
            .reduce((a, c) => [...c, ...a], [])
            .sort()
            .join('&') || '';
    const newUrl = `${router.asPath.split('?')?.[0]}${queryString ? `?${queryString}` : ''}`;

    window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
};
