import { SearchContentTypes } from '~/modules/search';

export const getSuggestionUrl = (searchTerm: string, searchType?: string) => {
    return `${process.env.NEXT_PUBLIC_WEB_URL}/api/suggestion/${searchTerm}${searchType ? `?type=${searchType}` : ''}`;
};

export const getSuggestionInitialContentUrl = () => {
    return `${process.env.NEXT_PUBLIC_WEB_URL}/api/suggestion/initial-content`;
};

export const getSearchUrl = (search?: string | null, searchType = SearchContentTypes.Products) => {
    if (!search) {
        return null;
    }
    return `${process.env.NEXT_PUBLIC_WEB_URL}/api/search/${searchType}${search ? search : ''}`;
};
