import { API_URL } from '$constants';
import { brandLeg } from '$constants/env';
import { isDefined, sortUrlQuery } from '$lib/helpers';
import { SearchContentTypes } from '~/modules/search';
import { PAGE_SIZE } from './filter';
import { mapFacetListToQuery } from './filter.helper';
import { FacetList } from './filter.types';

export const getUrlKey = (
    index: number,
    search: string,
    mode: SearchContentTypes,
    filterId?: string,
    facetParams?: FacetList,
    pageSize = PAGE_SIZE,
    pid?: string,
    pageId?: string,
    sId?: string
) => {
    let tmp = `${API_URL}/scom/api/search/combinedsearch?search=${encodeURIComponent(search)}`;

    // For legekaeden we do not want authors, so for "all" we will specify desired contentTypes
    if (brandLeg) {
        if (mode && mode !== SearchContentTypes.All) {
            tmp += `&contentTypes=${mode}`;
        } else {
            tmp += `&contentTypes=${SearchContentTypes.Articles}`;
            tmp += `&contentTypes=${SearchContentTypes.Categories}`;
            tmp += `&contentTypes=${SearchContentTypes.Products}`;
            tmp += `&contentTypes=${SearchContentTypes.Series}`;
        }
    } else {
        tmp += mode && mode !== 'all' ? `&contentTypes=${mode}` : '';
    }
    tmp += filterId ? `&filterPageId=${filterId}` : '';
    tmp += pid ? `&pid=${pid}` : '';
    tmp += pageId ? `&pageId=${pageId}` : '';
    tmp += sId ? `&sid=${sId}` : '';
    tmp += isDefined(facetParams) ? `&${mapFacetListToQuery(facetParams)}` : '';
    tmp += `&PageIndex=${index}&pageSize=${pageSize}`;
    tmp = tmp.replace(/' '/g, '+');

    return sortUrlQuery(tmp);
};

export const getUrlKeyForSearch = (
    index: number,
    search: string,
    mode: SearchContentTypes,
    filterId?: string,
    facetParams?: FacetList,
    pageSize = PAGE_SIZE,
    pid?: string,
    pageId?: string,
    sId?: string
) => {
    if (!search) {
        return null;
    }
    let tmp = `${API_URL}/scom/api/search?search=${encodeURIComponent(search)}`;

    // For legekaeden we do not want authors, so for "all" we will specify desired contentTypes
    if (brandLeg) {
        if (mode && mode !== SearchContentTypes.All && mode !== SearchContentTypes.Products) {
            tmp += `&filter=${mode}`;
        } else {
            tmp += `&filter=${SearchContentTypes.Series}`;
        }
    } else {
        tmp +=
            mode && mode !== 'all' && mode !== SearchContentTypes.Products
                ? `&filter=${mode}`
                : `&filter=${SearchContentTypes.Series}&filter=${SearchContentTypes.Authors}`;
    }
    tmp += filterId ? `&filterPageId=${filterId}` : '';
    tmp += pid ? `&pid=${pid}` : '';
    tmp += pageId ? `&pageId=${pageId}` : '';
    tmp += sId ? `&sid=${sId}` : '';
    tmp += isDefined(facetParams) ? `&${mapFacetListToQuery(facetParams)}` : '';
    tmp += `&PageIndex=${index}&pageSize=${pageSize}`;
    tmp = tmp.replace(/' '/g, '+');

    return sortUrlQuery(tmp);
};
