import { ISanitizedFilterOutput } from '$models';

export interface ISearchParams {
    search: string;
    pageIndex?: number;
    pageSize?: number;
    filter?: string[];
    siteName?: string;
    language?: string;
    pid?: string;
    sid?: string;
    pageId?: string;
}

export type ISearchResponse = {
    products: ISanitizedFilterOutput;
} & ISanitizedFilterOutput;

export enum SearchContentTypesSingleSearch {
    All = 'all',
    Articles = 'articles',
    Authors = 'authors',
    Brands = 'brands', // This is used for search word suggestions
    Categories = 'categories',
    Products = 'products',
    Series = 'series',
}

export enum SearchContentTypesOldSearch {
    Articles = 'articles',
    Authors = 'authors',
    Categories = 'categories',
    Products = 'products',
    Series = 'series',
}

export enum SearchContentTypes {
    All = 'all',
    BlogPost = 'blogPost',
    Articles = 'articles',
    Authors = 'authors',
    Brands = 'brands', // This is used for search word suggestions
    Categories = 'categories',
    Products = 'products',
    Series = 'series',
    Store = 'sitePages',
}

// sitePages = stores on hello retail
